import { Image, Line, Page, Path, Svg, Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import {
  Listing,
  META_FIELDS,
  camelCaseToWords,
  getReportMetaLabelValues,
} from '~/models'
import { STATUSES, TYPES, cn, fmtAddress, transformImageUrl } from '~/utils'

const LucideImageOffSvg = ({
  width,
  height,
}: {
  width: number
  height: number
}) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    stroke="black"
    strokeWidth="2"
    strokeLineCap="round"
    strokeLinejoin="round">
    <Line x1="2" x2="22" y1="2" y2="22" />
    <Path d="M10.41 10.41a2 2 0 1 1-2.83-2.83" />
    <Line x1="13.5" x2="6" y1="13.5" y2="21" />
    <Line x1="18" x2="21" y1="12" y2="15" />
    <Path d="M3.59 3.59A1.99 1.99 0 0 0 3 5v14a2 2 0 0 0 2 2h14c.55 0 1.052-.22 1.41-.59" />
    <Path d="M21 15V5a2 2 0 0 0-2-2H9" />
  </Svg>
)

function getPageListingFields(pageListings: Listing[]) {
  const allMetaKeyLabels = [
    {
      key: 'availableSpaces',
      label: 'Available Spaces',
      count: 0,
    },
    {
      key: 'leaseRate',
      label: 'Lease Rate',
      count: 0,
    },
    {
      key: 'availableSf',
      label: 'Available SF',
      count: 0,
    },
    ...Object.keys(META_FIELDS)
      .filter((key) => META_FIELDS[key].render !== false)
      .map((key) => ({
        key,
        label: META_FIELDS[key].label
          ? META_FIELDS[key].label
          : camelCaseToWords(key),
        count: 0,
      })),
    {
      key: 'created',
      label: 'Created',
      count: 0,
    },
    {
      key: 'modified',
      label: 'Updated',
      count: 0,
    },
    {
      key: 'offMarketDate',
      label: 'Transacted Date',
      count: 0,
    },
    { key: 'tid', label: 'Listing ID', count: 0 },
  ]
  const pageMetaKeyLabels = [...allMetaKeyLabels]
  const listingMetaLabelValues: {
    [_id: string]: { key: string; label: string; value: string }[]
  } = {}

  pageListings.forEach((listing) => {
    const keyLabelValues = getReportMetaLabelValues(listing)
    listingMetaLabelValues[listing._id] = keyLabelValues
    keyLabelValues.forEach(({ key }) => {
      const index = pageMetaKeyLabels.findIndex((m) => key === m.key)
      if (index === -1) {
        console.warn('Key missing in global', key)
      } else {
        pageMetaKeyLabels[index].count += 1
      }
    })
  })

  return { pageMetaKeyLabels, listingMetaLabelValues }
}

export default function PdfListingTablePage({
  listings,
}: {
  listings: Listing[]
}) {
  function splitListingPages(listings: Listing[]) {
    const batches = []
    for (let i = 0; i < listings.length; i += 4) {
      batches.push(listings.slice(i, i + 4))
    }
    return batches
  }

  const listingPages = splitListingPages(listings)
  const widthClass = listings.length < 4 ? 'w-[27.3%]' : 'w-[20.5%]'
  return (
    <>
      {listingPages.map((pageListings, index) => {
        const { pageMetaKeyLabels, listingMetaLabelValues } =
          getPageListingFields(pageListings)

        const threshold = 32
        let filteredPageMetaKeyLabels = pageMetaKeyLabels
        for (var i = 0; i < 4; i++) {
          if (filteredPageMetaKeyLabels.length > threshold) {
            filteredPageMetaKeyLabels = pageMetaKeyLabels.filter(
              ({ count }) => count > i
            )
            if (filteredPageMetaKeyLabels.length <= threshold) {
              break
            }
          }
        }

        return (
          <Page
            key={index}
            wrap={false}
            size="LETTER"
            style={tw('p-8 font-sans text-xs')}
            orientation="portrait">
            <View style={tw('mb-4 flex flex-row')}>
              {pageListings.map((listing, i) => {
                return (
                  <View
                    key={listing._id}
                    style={tw(
                      cn(
                        'flex flex-col gap-2',
                        widthClass,
                        i === 0 && 'ml-[18%]'
                      )
                    )}>
                    {(listing.images || []).length > 0 ? (
                      <View style={tw('pr-px')}>
                        <Image
                          src={transformImageUrl(listing.images![0].thumbUrl)}
                        />
                      </View>
                    ) : (
                      <View
                        style={tw(
                          cn(
                            'flex flex-row items-center justify-center bg-gray-200',
                            listings.length > 3 ? 'h-[65px]' : 'h-[86px]'
                          )
                        )}>
                        <LucideImageOffSvg width={20} height={20} />
                      </View>
                    )}
                    <Text style={tw('text-xs leading-tight')}>
                      {fmtAddress(listing.property)}
                    </Text>
                  </View>
                )
              })}
            </View>
            <View
              style={tw(
                'flex h-[18px] flex-row items-center border-b border-t border-border leading-[18px]'
              )}>
              <View style={tw('w-[18%]')}>
                <Text style={tw('font-semibold')}>Name</Text>
              </View>
              {pageListings.map((listing) => {
                return (
                  <View key={listing._id} style={tw(cn(widthClass, 'pr-1'))}>
                    <Text style={tw('font-semibold')}>{listing.name}</Text>
                  </View>
                )
              })}
            </View>
            <View
              style={tw(
                'flex h-[18px] flex-row items-center border-b border-border leading-[18px]'
              )}>
              <View style={tw('w-[18%]')}>
                <Text style={tw('font-semibold')}>Type</Text>
              </View>
              {pageListings.map((listing) => {
                return (
                  <View key={listing._id} style={tw(widthClass)}>
                    <Text>{TYPES[listing.type]}</Text>
                  </View>
                )
              })}
            </View>
            <View
              style={tw(
                'flex h-[18px] flex-row items-center border-b border-border leading-[18px]'
              )}>
              <View style={tw('w-[18%]')}>
                <Text style={tw('font-semibold')}>Status</Text>
              </View>
              {pageListings.map((listing) => {
                return (
                  <View key={listing._id} style={tw(widthClass)}>
                    <Text>{STATUSES[listing.status]}</Text>
                  </View>
                )
              })}
            </View>
            {filteredPageMetaKeyLabels.map(({ key, label }) => {
              return (
                <View
                  key={key}
                  style={tw(
                    'flex h-[18px] flex-row items-center border-b border-border leading-[18px]'
                  )}>
                  <View key={key} style={tw('w-[18%]')}>
                    <Text style={tw('font-semibold')}>{label}</Text>
                  </View>
                  {pageListings.map((listing) => {
                    const keyLabelValues = listingMetaLabelValues[
                      listing._id
                    ].find((m) => m.key === key)
                    return (
                      <View
                        key={listing._id}
                        style={tw(cn(widthClass, 'pr-1'))}>
                        <Text>{keyLabelValues?.value || '-'}</Text>
                      </View>
                    )
                  })}
                </View>
              )
            })}
          </Page>
        )
      })}
    </>
  )
}
