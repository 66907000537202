import { Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import { type Listing, type ListingLeaseSpace } from '~/models'
import { RATE_TYPES, cn, fmtNumberAbbr } from '~/utils'

function getLeaseSpaceRows({
  listing,
  showAll = false,
  isNumAbbr = false,
}: {
  listing: Listing
  showAll?: boolean
  isNumAbbr?: boolean
}) {
  const spaces = showAll
    ? [...(listing.leaseSpaces || [])]
    : [...(listing.leaseSpaces || [])].filter((s) => s.available)
  spaces.sort((a, b) => a.modified.localeCompare(b.modified))

  if (spaces.length === 0) {
    return { headers: [], rows: {} }
  }

  const maybeFmtNumberAbbr = (value?: number) => {
    if (isNumAbbr) {
      return fmtNumberAbbr(value)
    }
    return value?.toLocaleString()
  }

  const getSpaceRate = (space: ListingLeaseSpace) => {
    const min = space.minRate
    const max = space.maxRate
    const rateType =
      space.rateType && typeof RATE_TYPES[space.rateType] !== 'undefined'
        ? ` /${RATE_TYPES[space.rateType]}`
        : ''
    if (!min && !max) {
      return 'Negotiable'
    } else if (min && !max) {
      return `$${maybeFmtNumberAbbr(min)} ${rateType}`
    } else if (min && max && min !== max) {
      return `$${maybeFmtNumberAbbr(min)} - $${maybeFmtNumberAbbr(
        max
      )}${rateType}`
    }
    return `$${maybeFmtNumberAbbr(min)}${rateType}`
  }

  const getSquareFootage = (space: ListingLeaseSpace) => {
    const min = space.minAvailableSf
    const max = space.maxAvailableSf
    if (!min && !max) {
      return null
    } else if (min !== max) {
      return `${maybeFmtNumberAbbr(min)} - ${maybeFmtNumberAbbr(max)} SF`
    }
    return `${maybeFmtNumberAbbr(min)} SF`
  }

  const rows: { [key: string]: any } = {}
  const headers: { key: string; label: string }[] = []

  spaces.forEach((space) => {
    rows[space._id] = {
      name: space.name,
      rate: getSpaceRate(space),
      footage: getSquareFootage(space),
      acres: space.acres
        ? space.acres
            .toLocaleString(undefined, { minimumFractionDigits: 4 })
            .replace(/(\.\d*?[1-9])0+$/, '$1')
            .replace('.0000', '') + ' ac'
        : '',
      sublease: space.sublease ? 'Yes' : '',
      available: showAll ? space.available : '',
    }
  })

  const spaceHeaders = [
    { key: 'name', label: 'Name' },
    { key: 'rate', label: 'Rate' },
    { key: 'footage', label: 'Footage' },
    { key: 'acres', label: 'Acres' },
    { key: 'sublease', label: 'Sublease' },
    { key: 'available', label: 'Available' },
  ]
  spaceHeaders.forEach((header) => {
    if (Object.values(rows).some((row) => row[header.key])) {
      headers.push(header)
    }
  })

  return { headers, rows }
}

export default function PdfLeaseSpaces({ listing }: { listing: Listing }) {
  const { headers, rows } = getLeaseSpaceRows({ listing, isNumAbbr: false })

  if (headers.length === 0) {
    return null
  }

  return (
    <View style={tw('mt-4')} break={false}>
      <Text style={tw('text-base font-heading leading-none mb-4')}>
        Lease Spaces
      </Text>
      <View style={tw('flex flex-row')}>
        {headers.map((header, index) => (
          <View key={header.key} style={index === 0 ? tw('flex-1') : undefined}>
            <View style={tw('border-b border-slate-200 pb-1 pr-2')}>
              <Text style={tw('font-sans font-medium text-base')}>
                {header.label}
              </Text>
            </View>
            {listing.leaseSpaces!.map(({ _id }) => {
              if (typeof rows[_id] === 'undefined') {
                // not available
                return null
              }
              return (
                <View key={_id} style={tw('py-1 pr-4')}>
                  <Text
                    style={tw(
                      cn('font-sans text-sm', index === 0 && 'font-medium')
                    )}>
                    {rows[_id][header.key]}
                  </Text>
                </View>
              )
            })}
          </View>
        ))}
      </View>
    </View>
  )
}
