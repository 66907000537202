import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { Html } from 'react-pdf-html'
import { PdfBadge, tw } from '~/components/pdfs'
import type {
  Company,
  Listing,
  Organization,
  ParcelData,
  ReportPdfOptions,
  User,
} from '~/models'
import {
  STATUSES,
  TYPES,
  cn,
  fmtAddress,
  getMaxSubstringLength,
  getPropertyTypeClassName,
  transformImageUrl,
} from '~/utils'
import { PdfLeaseSpaces, PdfListingBrokers, PdfListingMeta } from './components'
import {
  PdfDisclaimersPage,
  ReportPdfImagesPage,
  ReportPdfMapPage,
  ReportPdfParcelPage,
  ReportPdfTitlePage,
} from './pages'

export default function ReportFlyersPdf({
  organization,
  title,
  user,
  company,
  listings,
  parcels,
  pdfOptions,
}: {
  organization: Organization
  title: string
  user: User
  company?: Company
  listings: Listing[]
  parcels: ParcelData[]
  pdfOptions: ReportPdfOptions
}) {
  return (
    <Document title={title}>
      {pdfOptions.showTitlePage && (
        <ReportPdfTitlePage
          title={title}
          company={company}
          user={user}
          listings={listings}
          pdfOptions={pdfOptions}
          orientation="portrait"
        />
      )}
      {pdfOptions.showMapPage && (
        <ReportPdfMapPage
          listings={listings}
          pdfOptions={pdfOptions}
          orientation="portrait"
        />
      )}
      {listings.map((listing, index) => {
        const name = listing.name.substring(
          0,
          getMaxSubstringLength(listing.name, 52)
        )
        return (
          <React.Fragment key={listing._id}>
            <Page id={listing.tid} size="LETTER" style={tw('p-8 font-sans')}>
              <View style={tw('font-sans')}>
                <View style={tw('flex flex-row items-start gap-4')}>
                  <PdfBadge
                    className={cn(
                      'mt-px text-white',
                      listing.type === 'sale' &&
                        'bg-[#0284c7] hover:bg-[#0284c7]',
                      listing.type === 'lease' &&
                        'bg-[#c026d3] hover:bg-[#c026d3]',
                      listing.type === 'saleLease' &&
                        'bg-[#4f46e5] hover:bg-[#4f46e5]'
                    )}>
                    {STATUSES[listing.status]}
                  </PdfBadge>
                  <Text style={tw('font-heading text-2xl leading-tight')}>
                    {name.length < listing.name.length ? `${name}...` : name}
                  </Text>
                </View>
                <Text style={tw('mt-1 font-sans text-lg')}>
                  {fmtAddress(listing.property, true)}
                </Text>
              </View>
              <View style={tw('mt-2 flex flex-row gap-6')}>
                <View style={tw('w-7/12')}>
                  {(listing.images || []).length > 0 ? (
                    <Image
                      style={tw('h-[179px] w-[319px] rounded-sm')}
                      src={transformImageUrl(listing.images![0].thumbUrl)}
                    />
                  ) : (
                    <View style={tw('rounded-sm bg-gray-200 pb-[56.25%]')} />
                  )}

                  {(listing.meta.description || listing.meta.highlights) && (
                    <View style={tw('mt-4')}>
                      <Text
                        style={tw('font-heading mb-4 text-base leading-none')}>
                        Overview
                      </Text>
                      {listing.meta.description && (
                        <Html
                          style={tw('font-sans text-sm leading-tight')}
                          stylesheet={{
                            p: { margin: '0 0 10px' },
                            li: { margin: 0 },
                            ul: { margin: 0 },
                            'ul > ul': { margin: 0 },
                          }}>
                          {/* TODO Add logic for when to cut */}
                          {listing.meta.description.length > 1000
                            ? `${listing.meta.description.slice(0, 1000)}...`
                            : listing.meta.description}
                          {/* {listing.meta.description} */}
                        </Html>
                      )}
                      {listing.meta.highlights && (
                        <Html
                          style={tw('font-sans text-sm leading-tight')}
                          stylesheet={{
                            p: { margin: '0 0 10px' },
                            li: { margin: 0 },
                            ul: { margin: 0 },
                            'ul > ul': { margin: 0 },
                          }}>
                          {listing.meta.highlights}
                        </Html>
                      )}
                    </View>
                  )}

                  <PdfLeaseSpaces listing={listing} />
                </View>

                <View style={tw('w-5/12')}>
                  <View style={tw('mb-2 flex flex-row items-start gap-4')}>
                    <Text style={tw('font-heading text-xl leading-none')}>
                      For {TYPES[listing.type]}
                    </Text>

                    {listing.propertyTypes.slice(0, 1).map((value, index) => {
                      return (
                        <PdfBadge
                          key={index}
                          className={cn(
                            'text-white',
                            getPropertyTypeClassName(value)
                          )}>
                          {value}
                        </PdfBadge>
                      )
                    })}
                  </View>
                  {(listing.propertySubtypes || []).length > 0 && (
                    <Text style={tw('mb-3 font-sans text-sm italic')}>
                      Secondary Uses:{' '}
                      {listing.propertySubtypes!.slice(0, 4).join(', ')}
                    </Text>
                  )}
                  <PdfListingMeta listing={listing} pdfOptions={pdfOptions} />
                  {pdfOptions.showBrokers && (
                    <PdfListingBrokers listing={listing} />
                  )}
                </View>
              </View>
              {listings.length > 1 && (
                <View fixed style={tw('absolute right-4 top-4')}>
                  <Text style={tw('font-sans text-sm italic')}>
                    Listing {index + 1} of {listings.length}
                  </Text>
                </View>
              )}
            </Page>
            {pdfOptions.showImages && <ReportPdfImagesPage listing={listing} />}
            {pdfOptions.showParcels && (
              <ReportPdfParcelPage listing={listing} parcels={parcels} />
            )}
          </React.Fragment>
        )
      })}
      <PdfDisclaimersPage
        pdfOptions={pdfOptions}
        organization={organization}
        orientation="portrait"
      />
    </Document>
  )
}
